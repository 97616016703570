jQuery(function ($) {
  $(document).ready(function () {
    $(".nav-link").on('click', function (event) {
      if (this.hash !== "") {

        var hash = this.hash;
        $('html, body').animate({
          scrollTop: $(hash).offset().top - 70
        }, 800);
        if (window.matchMedia('(max-width: 767px)').matches) {
          event.preventDefault();
          $('.navbar-collapse').collapse('hide');
          $(".navbar-toggler").toggleClass('is-active');
        }
      }
    });
    $(".navbar-toggler").on('click', function (event) {
      $(".navbar-toggler").toggleClass('is-active');
    });
    var header = $(".navbar");
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 100) {
        header.addClass("navbar--scrolled");
      } else {
        header.removeClass("navbar--scrolled")
      }
    });
  });
})